/* eslint-disable prettier/prettier */
// document.addEventListener("contextmenu", function(e){
//     e.preventDefault();
//     }, false);

//     document.addEventListener("keydown", (e) => {
//         if (e.ctrlKey || e.keyCode==123) {
//             e.stopPropagation();
//             e.preventDefault();
//         }
//     });

var datetimepicker1,datetimepicker2;
function initializeCalendar() {
   
   // disableControls(true);
   datetimepicker1 =  $('#datetimepicker1').datetimepicker({
          viewMode: 'years',
          format: 'YYYY'
      });
   datetimepicker2 = $('#datetimepicker2').datetimepicker({
        useCurrent: false, //Important! See issue #1075
        viewMode: 'years',
          format: 'YYYY'
    });
   $("#datetimepicker1").on("dp.change", function (e) {
       $('#datetimepicker2').data("DateTimePicker").minDate(e.date);
   });
   $("#datetimepicker2").on("dp.change", function (e) {
       $('#datetimepicker1').data("DateTimePicker").maxDate(e.date);
   });

  
}
function addControl(select){
    // working but disabled to allow add control button even no contract selected
    // if ( $("#selectedContracts").val().length == 0 && $("#selectContracts").val() == "-1" ){
    //     alertMsg("Please Choose Contract");
    //     return "0"
    // }
    var selectedMainSection = $("ul#mainSectionTabs li.active");
    if ( selectedMainSection.length > 0 ) {
        selectedMainSection = selectedMainSection[0];
        var selectedMainTabName = selectedMainSection.firstElementChild.href;
        selectedMainTabName = selectedMainTabName.substr(selectedMainTabName.indexOf("#") + 1);
        // get category tab 

        var secondSelectedTab = $("ul#"+selectedMainTabName+"SectionTabs li.active");
        if(secondSelectedTab.length > 0){
            secondSelectedTab = secondSelectedTab [0];
            var selectedSecondTabName = secondSelectedTab.firstElementChild.href;
            selectedSecondTabName = selectedSecondTabName.substr(selectedSecondTabName.indexOf("#") + 1);            
            var selectedAtrribute;
            if ( selectedSecondTabName.indexOf("single") >= 0 ){
                if ( selectedSecondTabName.indexOf("reportsTab") >= 0 ){
                    selectedAtrribute = {   
                        reportType: $("#ddlReportType").val(),
                        ContractId: $("#selectContracts").val(),
                        ContractName:$("#selectContracts  option:selected").text(),
                        indicator: $("#selectKPI").val(),
                        indicatorName: $("#selectKPI  option:selected").text(),
                        granularity: $("#selectGranularity").val(),
                        startDate:$("#txtDatetimepicker1").val(),
                        endDate:$("#txtDatetimepicker2").val()
                    };
                }
                else if ( selectedSecondTabName.indexOf("freeformreportTab") >= 0 ){
                    if ($("#ddlFreeFormReportPresentationType").val() == "chart") {
                        selectedAtrribute = {
                            reportType: $("#ddlFreeFormReportType option:selected").text(),
                            reportTypeId: $("#ddlFreeFormReportType").val(),
                            uniqueId:'id' + (new Date()).getTime(),
                            reportFormat: $("#ddlFreeFormReportPresentationType option:selected").text()
                        };
                    } else {
                        selectedColumns = '';
                        if ($("input:checkbox[name='freeFormReportColumn']:checked").length == 0) {
                            //alert("Please select columns");
                            selectedColumns;
                        } else {
                            select.map(data=>{
                                selectedColumns+=data+','
                            })
                            // $("input:checkbox[name='freeFormReportColumn']:checked").each((item, index) => { selectedColumns = selectedColumns + index.value[0].toUpperCase() + index.value.slice(1) + ',' });
                            selectedColumns = selectedColumns.substr(0, selectedColumns.length - 1);
                        }
                        selectedAtrribute = {
                            reportType: $("#ddlFreeFormReportType option:selected").text(),
                            reportTypeId: $("#ddlFreeFormReportType").val(),
                            selectedColumns: selectedColumns,
                            uniqueId:'id' + (new Date()).getTime(),
                            reportFormat: $("#ddlFreeFormReportPresentationType option:selected").text()
                        };
                    }
                }
                //else if ( selectedSecondTabName.indexOf("customersTab") >= 0 ){
                else{
                    selectedAtrribute = $("input:radio[name='"+selectedSecondTabName+"']:checked").val();
                }
                
            }else{
                selectedAtrribute = $("input:checkbox[name='"+selectedSecondTabName+"']:checked");
            }
            if (selectedAtrribute.length > 0 || typeof selectedAtrribute == 'object' ){
                if(selectedMainTabName == "singleTab"){
                    return { TabName: selectedMainTabName, TabType: selectedSecondTabName, AttirbuteVal : typeof selectedAtrribute == 'object' ? selectedAtrribute : selectedAtrribute};
                }
                return { TabName: selectedMainTabName, TabType: selectedSecondTabName, AttirbuteVal : selectedAtrribute};
            }else{
            // console.log($("input:radio[name='"+selectedSecondTabName+"']:checked").val())
                return 0;
            }
        //     if (selectedAtrribute.length > 0 ){
        //         return selectedSecondTabName+ "#" + selectedAtrribute.val();
        //     }else{
        //    // console.log($("input:radio[name='"+selectedSecondTabName+"']:checked").val())
        //          return 0;
        //     }

        }

    }
}


function disableControls(state) {
    // working but disabled to allow add control button even no contract selected
    // $(document).find('input').each(function () {
    //     $(this).attr('disabled', state);
    // });
    $(document).find('button').each(function () {
        $(this).attr('disabled', state);
    });
    $(document).find('select').each(function () {
        if (this.id == "selectContracts" || this.id == "publishType" || this.id == "selectMultipleContracts" || this.id == "ddlFreeFormReportType" || this.id == "ddlFreeFormReportPresentationType" || this.id == "ddSingleTabFilter"
        || this.classList.contains("multiDropDownFilter")){
            //do nothing
        }else{
            $(this).attr('disabled', state);
        }
    });
    $("#add-content-in-control").attr('disabled',false);
    $("#saveTemplate,.multiDropDownFilter").attr('disabled',false);
    $('#logoutButton').attr('disabled',false);
    $('#addFilter').attr('disabled',false);
    $('#removeFilter').attr('disabled',false);
    $('#variablesDropDownType').attr('disabled',false);
    $('#buttonCreateVariable').attr('disabled',false);
    $('#buttonUpdateVariables').attr('disabled',false);
    $('#buttonDeleteVariables').attr('disabled',false);
}


function loadCalendar(e){
    datetimepicker1.data("DateTimePicker").clear();
    datetimepicker2.data("DateTimePicker").clear();
    if ( e.value == "years"){
        datetimepicker1.data("DateTimePicker").format('YYYY');
        datetimepicker2.data("DateTimePicker").format('YYYY');
    }else if (e.value == "months"){
        datetimepicker1.data("DateTimePicker").format('M-YYYY');
        datetimepicker2.data("DateTimePicker").format('M-YYYY');
    }else if (e.value == "days"){
        datetimepicker1.data("DateTimePicker").format('DD-MM-YYYY');
        datetimepicker2.data("DateTimePicker").format('DD-MM-YYYY');
    }

    datetimepicker1.data("DateTimePicker").viewMode( e.value);
    datetimepicker2.data("DateTimePicker").viewMode( e.value);


}
isMultiSelectIntialize = false;
function makeMultiSelectDropDown(){
    window.setTimeout(function(){
        if ( $("#publishType").val() == 2 && isMultiSelectIntialize == false ){
            isMultiSelectIntialize = true;
        $(".chosen-select").chosen({
            no_results_text: "Oops, nothing found!"
          }).change( function() {
            $("#selectedContracts").val($(this).val().toString())
            if( $(this).val().toString().length > 0 ){
                disableControls(false);
                $("#messPublish").show();
            }else{
                disableControls(true);
            }
        });
        // if($("#publishType").val() != 2){
        //     $(".chosen-container").hide();
        // }
        $(".chosen-container").css("width", "200px");
    }
    },500)
    
}

function getSelectedContractList(){
    var contracts = $("#selectedContracts").val();
    return contracts;
}
function showHideContractSelectionAsPerPublishType(){
    disableControls(true);
    if ( $("#publishType").val() == 1 ){
        $("#selectContracts").show();
        $("#replace-content-in-control").show();
        //$("#selectMultipleContracts").hide();
        $(".chosen-container").hide();
        $("#messPublish").hide();
    }else{
        $("#selectContracts").hide();
        $("#replace-content-in-control").hide();
        if ( isMultiSelectIntialize == false ){
            $("#selectMultipleContracts").show();
        }
        $(".chosen-container").show();
        if ( $(".chosen-select").val().toString().length > 0 ){
            $("#messPublish").show();
        }
        $("#messPublish").show();
        makeMultiSelectDropDown();
    }
}

function alertMsg(msg,color){
    $("#alertArea").text(msg).css('color', color);
    window.setTimeout(()=> {$("#alertArea").text("")},5000)
}

function alertMsg(msg,color,target){
    $("#"+target).text(msg).css('color', color);
    window.setTimeout(()=> {$("#"+target).text("")},5000)
}
function alertMsg(msg,color,target,interval){
    if ( target == undefined || target == "")
        target = "alertArea";
    if ( interval == undefined )
        interval = 5000;
    $("#"+target).text(msg).css('color', color);
    window.setTimeout(()=> {$("#"+target).text("")},interval)
}

function enableDisableMessPublish(val){
    $("#messPublish").attr('disabled',val);
}
function enableDisableQuickPublish(val){
    $("#replace-content-in-control").attr('disabled',val);
}

function getFilterSelection(option) {
  let options = option.split("#");
  if (options[1] == "delete") {
    $("#" + options[0] + " #" + options[2]).remove();
  }
  let dataArry = [];
    $("#" + options[0]+" .filter-row").each(function (){
        dataArry.push({operand: $("#"+this.id +" #ddSingleTabFilter").val().toLocaleLowerCase(),operator:$("#"+this.id +" #ddlOperator").val(),enteredValue: $("#"+this.id +" #enteredValue").val(), targetTag: $("#"+this.id+" #ddselectTag").attr('name')} );
    })
    if(options[1] == "add"){
        $("#" + options[0] + " #" + options[2]).remove();
      }
  return dataArry;
  
}