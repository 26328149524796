import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _session: any
    set session(value){
        this._session = value
        sessionStorage.setItem('currentUser', JSON.stringify(value))
    }

    get session(){
        return(this._session? this._session : JSON.parse(sessionStorage.getItem('currentUser')))
    }
}