import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import * as shajs from 'sha.js';
import {env_address_param} from './environmentparameter';
import { environment } from 'src/environments/environment';
import { StorageService } from './_storage.service';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })
// export class AuthenticationService {
//     private currentUserSubject: BehaviorSubject<User>;
//     public currentUser: Observable<User>;

//     constructor(private http: HttpClient) {
//         this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
//         this.currentUser = this.currentUserSubject.asObservable();
//     }

//     public get currentUserValue(): User {
//         return this.currentUserSubject.value;
//     }

//     login(username: string, password: string) {
//         const httpOptions = {
//             headers: new HttpHeaders({
//               'Authorization':  "Basic " + btoa(environment.API_Credential)
//             })
//           };

//         return this.http.get<any>('https://'+env_address_param+'/api/Data/Login?username='+username+'&password='+shajs('sha256').update(environment.salt + shajs('sha256').update(password).digest('hex')).digest('hex'),httpOptions)
//             .pipe(map(user => {
//                 // login successful if there's a jwt token in the response
//                 if (user && user.token) {
//                     // store user details and jwt token in local storage to keep user logged in between page refreshes
//                     localStorage.setItem('currentUser', JSON.stringify(user));
//                     this.currentUserSubject.next(user);
//                 }

//                 return user;
//             }));
//     }

//     logout() {
//         // remove user from local storage to log user out
//         localStorage.removeItem('currentUser');
//         this.currentUserSubject.next(null);
//     }
// }

export class AuthenticationService {

    constructor(
        private router: Router,
        private http: HttpClient,
        private storageLogin: StorageService
    ){}

    login(username: string, password: string) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Authorization':  "Basic " + btoa(environment.API_Credential)
            })
          };

        return this.http.get<any>('https://'+env_address_param+'/api/Data/Login?username='+username+'&password='+shajs('sha256').update(environment.salt + shajs('sha256').update(password).digest('hex')).digest('hex'),httpOptions)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                    this.storageLogin.session=user
                    // this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout(){
        this.storageLogin.session=null;
        sessionStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
    }
}