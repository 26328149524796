// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {env_address_param}  from '../app/_services/environmentparameter';
export const environment = {
  //API_URL: "https://addin.quantis.it/api/evidence/",
  API_URL: "https://" + env_address_param + "/api/evidence/",
  API_URL_NODE:"https://" + env_address_param + "/systemengine/",
  production: false,
  salt: 'p4ssw0rd',
  token:'fe7acaa6476c4fcde58d4e636234c1e5',
  API_Credential:"Quantis:WorkflowAPI",
  EmptyValueMessage: "#####",
  NoDataFromAPIResponse: "No Data from API Response",
  //DocumentPostURL:"https://addin.quantis.it/systemengine/booklet/POST_EvidenceBookletToGenerate",
  DocumentPostURL:"https://" + env_address_param + "/systemengine/booklet/POST_EvidenceBookletToGenerate",
  SaveTemplateURL:"https://" + env_address_param + "/systemengine/booklet/POST_EvidenceBookletSaveDocument",
  SuccessMsg: "Your job is queued successfully.",
  TemplateSavedMsg: "Template has been uploaded successfully.",
  NoTemplateName: "Template-Word",
  FailureMsg: "Somethig went wrong. Save your work and reload the Add-In to try again.",
  Tabs:{
    Single: {
      Customers: [{ key: 'address', val: 'Address' },{ key: 'city', val: 'City' },{ key: 'contact', val: 'Contact' },{ key: 'customer_type', val: 'Contract Party Type' },{ key: 'create_date', val: 'Create Date' },{ key: 'description', val: 'Description' },{ key: 'email', val: 'Email' },{ key: 'id_version', val: 'Version' },{ key: 'modify_date', val: 'Modify Date' },{ key: 'name', val: 'Name' },{ key: 'notes', val: 'Notes' },{ key: 'phone', val: 'Phone' },{ key: 'state', val: 'State' },{key: 'timezone',val: 'Timezone'},{ key: 'valid', val: 'Valid' },{ key: 'valid_from', val: 'Valid From' },{ key: 'valid_to', val: 'Valid To' },{ key: 'zipcode', val: 'ZipCode' }],
      Contracts: [{ key: 'create_date', val: 'Create Date' },{ key: 'description', val: 'Description' },{key:'localization', val:'Localization'},{ key: 'id_version', val: 'Version' },{ key: 'modify_date', val: 'Modify Date' },{ key: 'name', val: 'Name' },{ key: 'notes', val: 'Notes' },{ key: 'service_name', val: 'Service' },{ key: 'valid', val: 'Valid' },{ key: 'valid_from', val: 'Valid From' },{ key: 'valid_to', val: 'Valid To' }],
      Services: [{ key: 'create_date', val: 'Create Date' },{ key: 'description', val: 'Description' },{ key: 'modify_date', val: 'Modify Date' },{ key: 'name', val: 'Name' }],
      Indicators: [{ key: 'active', val: 'Active' },{ key: 'active_wf', val: 'Active_wf' },{ key: 'comparison_operator', val: 'Comparison_operator' },{ key: 'computing_description', val: 'Computing_description' },{ key: 'contract_name', val: 'Contract_name' },{ key: 'create_date', val: 'Create Date' },{ key: 'description', val: 'Description' },{key: 'id', val:'Id'},{ key: 'name', val: 'Name' },{ key: 'notes', val: 'Notes' },{ key: 'service_name', val: 'Service name' },{ key: 'target', val: 'Target' },{ key: 'timeslot_name', val: 'Timeslot' },{key:'timezone', val:'Timezone'},{ key: 'tracking_period', val: 'Tracking_period' },{ key: 'type', val: 'Type' },{ key: 'unit_measure_name', val: 'Unit_measure (name)' },{ key: 'unit_measure_symbol', val: 'Unit_measure (Symbol)' },{ key: 'valid', val: 'Valid' },{ key: 'valid_from', val: 'Valid From' },{ key: 'valid_to', val: 'Valid To' },{ key: 'id_version', val: 'Version' }],
      Thresholds: [{ key: 'active', val: 'Active' },{ key: 'color', val: 'Color' },{ key: 'comparison_operator', val: 'Comparison_operator' },{ key: 'kpi_name', val: 'Kpi_name_(List)' },{ key: 'name', val: 'Name' },{ key: 'value', val: 'Value' }],
      Paramters: [{ key: 'create_date', val: 'Create Date' },{ key: 'id_Version', val: 'id_Version' },{ key: 'id_contract', val: 'Contract' },{ key: 'indicator_name', val: 'Metric_name' },{ key: 'modify_date', val: 'Modify Date' },{ key: 'name', val: 'Name' },{ key: 'type', val: 'Type' },{ key: 'valid', val: 'Valid' },{ key: 'valid_from', val: 'Valid From' },{ key: 'valid_to', val: 'Valid To' },{ key: 'value', val: 'Value / Values (List)' }],
      Attributes: [],
      ContractFilter: [{ name: 'Please select option', selected: false },{ name: 'Customer', val:'customer', selected: false }, { name: 'Name', val:'name', selected: false }],
      CustomerFilter: [{ name: 'Please select option', selected: false },{ name: 'Name', val:'name', selected: false }],
      ServiceFilter: [{ name: 'Please select option', selected: false },{ name: 'Name', val:'name', selected: false }],
      IndicatorFilter: [{ name: 'Please select option', selected: false },{ name: 'Active', val:'active', selected: false }, { name: 'Contract name', val: 'contract_name', selected: false }, { name: 'Customer1 (Primary Contract Party)', val: 'id_primary_contract_party', selected: false }, { name: 'Customer2 (Secondary Contract Party)', val:'id_secondary_contract_party', selected: false }, { name: 'Description', val:'description', selected: false }, { name: 'Name', val:'name', selected: false }, { name: 'Tracking Period', val:'tracking_period', selected: false }, { name: 'Type', val:'type', selected: false },{name: 'Id metrics', val:'id', selected: false}],
      ThresholdFilter: [{ name: 'Please select option', selected: false },{ name: 'Active', val:'active', selected: false }, { name: 'Name', val:'name', selected: false }, { name:'Metrics', val:"id_indicator", selected: false }],
      ParametersFilter: [{ name: 'Please select option', selected: false },{ name: 'Contract', val:'id_contract', selected: false }, { name: 'Indicator name', val:'indicator_name', selected: false }, { name: 'Name', val:'name', selected: false }, { name: 'Type',val: 'type', selected: false }, { name: 'Metrics', val: 'id_indicator', selected: false}],
    },
    List:{
      Customers: {},
      Contracts: {},
      Services:{},
      Indicators:{},
      Thresholds:{},
      Paramters:{},
      Attributes:{}
    },
    Table:{
      Customers: {},
      Contracts: {},
      Services:{},
      Indicators:{},
      Thresholds:{},
      Paramters:{},
      Attributes:{}
    },
    Report:{
      Table:[{key:'metric_id', val:'Metric id'},{key:'metric_id_version', val:'Metric id version'}, {key:'metric_name',val:'Metric name'}, {key:'result',val:'Result'}, {key:'target',val:'Target'}, {key:'date', val:'Date'}, {key:'calculation_timestamp', val:'Calculation Timestamp'}, {key:'comparison_operator',val:'Comparison Operator'}, {key:'unit_measure',val:'Unit Measure'}, {key:'tracking_period', val:'Tracking Period'}, {key:'aggregate_target', val:'Aggregate Target'}, {key:'period', val:'Period'}, {key:'number', val:'Number'}]
    }
  },
  DummyHeading: "Heading",
  DummyRow:"Value"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
